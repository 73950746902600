// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import clamp from './clamp'

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Method
// ----------------------------------------------------------------------------
/** linear interpolation between a and b using a normalized value amount */
const lerp = (a, b, amount, clamped = false) =>
  clamped ? clamp(a + (b - a) * amount, 0, 1) : a + (b - a) * amount

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default lerp

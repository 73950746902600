// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import isUndefined from 'lodash/isUndefined'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import getRandomPalette from '../color/getRandomPalette'

import array from '../../methods/array'
import shuffle from '../../methods/shuffle'
import randInt from '../../methods/randInt'
import random from '../../methods/random'
import map from '../../methods/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

const w = 1280
const h = 728
const p = getRandomPalette()
const palette = shuffle(p)

let domCache

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const SVG = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1280 728"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0" y="0" width={w} height={h} stroke="none" fill="none" />
    <Fragment>
      {array(30).map((i) => {
        const nbRects = randInt(5, 25)
        const rad = randInt(2, 8) * 60
        const rw = randInt(4, 15)
        const rh = randInt(5, 100)
        const startAngle = random(720)
        const stepAngle = randInt(Math.max(1, rw - rad / 60), rw / 2)
        const nbHashes = (random(0.05, 0.4) * rh) | 0
        const color = random(palette)

        return (
          <Fragment>
            {array(nbRects).map((i) => {
              return (
                <g
                  transform={`translate(${w / 2}, ${h / 2}) rotate(${
                    startAngle + i * stepAngle
                  }) translate(0, ${rad - rh / 2})`}
                >
                  <rect
                    x={`${-rw / 2}`}
                    y="0"
                    width={rw}
                    height={rh}
                    stroke={color}
                    fill="none"
                  />
                  {array(nbHashes).map((j) => {
                    const y = map(j, -1, nbHashes, 0, rh)
                    return (
                      <line
                        x1={`-${rw / 2}`}
                        y1={y}
                        x2={rw / 2}
                        y2={y}
                        stroke={color}
                      />
                    )
                  })}
                </g>
              )
            })}
          </Fragment>
        )
      })}
    </Fragment>
  </svg>
)

/**  */
class Graph extends React.PureComponent {
  /** standard constructor */
  constructor(props) {
    super(props)

    // this.ref = React.createRef()
  }

  // /** [componentDidMount description] */
  // componentDidMount() {
  //   if (domCache) {
  //     this.ref.current.appendChild(domCache.cloneNode(true))
  //   } else {
  //     domCache = this.ref.current.firstChild
  //   }
  // }

  /** [render description] */
  render() {
    // const children = domCache ? null : <SVG />

    return (
      <div ref={this.ref}>
        <h2>Scale of the Aura Network</h2>
        <div
          className="as-paragraph"
          style={{ maxWidth: '59rem', width: '59rem' }}
        >
          <SVG />
        </div>
      </div>
    )
  }
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Graph

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Link from '../components/link'
import '../components/link/style.less'

import Graph1 from '../components/graphs/graph1'
import Graph2 from '../components/graphs/graph2'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Insights',
  slug: '/insights',
  abstract: 'Insights',
  breadcrumbs: [
    { title: 'Homepage', slug: '' },
    { title: 'Insights', slug: '/insights' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(relativePath: { eq: "insights/img1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img2: file(relativePath: { eq: "insights/img2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/**  */
class Page extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)

    this.state = {
      atGraph: 1,
    }

    this.atGraph = this.atGraph.bind(this)
  }

  /** [onFinish description] */
  atGraph(atGraph) {
    this.setState({ atGraph })
  }

  /** standard renderer */
  render() {
    const { atGraph } = this.state

    return (
      <StandardPageWrapper
        className="insights"
        pageSchema={pageSchema}
        {...this.props}
      >
        <div className="container">
          <h1>Insights at the edge</h1>
          <p className="hero">
            The Aura network generates thousands of data points every day, and
            our team is proud to elevate data visualization into an artform, and
            present unseen patterns, connections and stories that exist within
            our data. What we have found is that our community is fundamentally
            inter-dependent and inter-arising, that differences do exist between
            various groups within the community, and that these differences do
            not ultimately serve the goals of the community.
          </p>
        </div>
        <div className="left">
          <div>
            <Link
              to="#"
              className="as-paragraph"
              style={{
                width: '4rem',
                height: '4rem',
                display: 'block',
              }}
              onClick={(e) => {
                e.preventDefault()
                this.atGraph(2)
              }}
            >
              <GatsbyImage image={getImage(this.props.data.img1)} />
            </Link>
            <Link
              to="#"
              className="as-paragraph"
              style={{
                width: '4rem',
                height: '4rem',
                display: 'block',
              }}
              onClick={(e) => {
                e.preventDefault()
                this.atGraph(1)
              }}
            >
              <GatsbyImage image={getImage(this.props.data.img2)} />
            </Link>
          </div>
          <div className="container">
            {atGraph === 1 && <Graph1 />}
            {atGraph === 2 && <Graph2 />}
          </div>
        </div>
      </StandardPageWrapper>
    )
  }
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page

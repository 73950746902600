// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import randInt from './randInt'

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Method
// ----------------------------------------------------------------------------
/** non destructive shuffle method based on Fisher–Yates shuffle: https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle */
const shuffle = (arr) => {
  let copy = [...arr] // create a copy of original array
  for (let i = copy.length - 1; i; i--) {
    let randomIndex = randInt(i + 1)
    ;[copy[i], copy[randomIndex]] = [copy[randomIndex], copy[i]] // swap
  }
  return copy
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default shuffle

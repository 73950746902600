// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import clamp from './clamp'
import lerp from './lerp'

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Method
// ----------------------------------------------------------------------------
/** linear interpolation from a range [a, b] to another range [c, d] */
const map = (n, a, b, c, d, clamped = false) =>
  clamped
    ? clamp(lerp(c, d, (n - a) / (b - a)), c, d)
    : lerp(c, d, (n - a) / (b - a))

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default map
